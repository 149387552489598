<template>
  <div
    class="d-flex justify-content-between"
    style="background-color: #ffffff;"
  >
    <div class=" h-100 col-xl-7 col-sm-12 col-xs-12">
      <div class="col-sm-12 col-xl-11 form header">
        <h3 class="text-dark fw-500 fs-32">
          Rejestracja
        </h3>
        <p>
          Utwórz swój profil i otwórz się na większą ilość klientów.
        </p>
        <div class="main_data">
          Dane podstawowe
        </div>

        <form>
          <div style="display: flex; align-items: center; margin-top: 10px;">

            <div>
              <label
                for="localization"
                class="border email_input col-xl-12 f-flex align-items-center"
                style="margin-top: 8px;"
              >
                <img
                  src="/assets/img/email.svg"
                  alt=""
                  class=""
                >
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="adres email"
                  class="border-0 h-100"
                  NgModel
                  required
                >
              </label>
            </div>

            <div>
              <label
                for="localization"
                class="border email_input col-xl-12"
                style="margin-top: 8px;margin-left: 8px;"
              >
                <img
                  src="/assets/img/email.svg"
                  alt=""
                  class=""
                >
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Imię i nazwisko"
                  class="border-0 h-100"
                  NgModel
                  required
                >
              </label>
            </div>

          </div>

          <div
            class="main_data"
            style="margin-top: 10px;"
          >
            Ustaw hasło
          </div>

          <div style="display: flex; align-items: center; margin-top: 10px;">

            <div>
              <label
                for="localization"
                class="border email_input col-xl-12"
                style="margin-top: 8px;"
              >
                <img
                  src="/assets/img/email.svg"
                  alt=""
                  class=""
                >
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Ustaw hasło"
                  class="border-0 h-100"
                  NgModel
                  required
                >
              </label>
            </div>

            <div>
              <label
                for="localization"
                class="border email_input col-xl-12"
                style="margin-top: 8px;margin-left: 8px;"
              >
                <img
                  src="/assets/img/email.svg"
                  alt=""
                  class=""
                >
                <input
                  id="password_repeat"
                  name="password_repeat"
                  type="password"
                  placeholder="Powtórz hasło"
                  class="border-0 h-100"
                  NgModel
                  required
                >
              </label>
            </div>

          </div>
        </form>

        <div class="mt-4 checkboxes">
          <input
            id="privacy_policy"
            type="checkbox"
          > Akceptacja <a
            href="#"
            style="color: #3772FF;"
          > regulaminu i polityki prywatności </a>
        </div>

        <div class="checkboxes">
          <input type="checkbox"> Zgoda na przesyłanie ofert
        </div>

        <div class="checkboxes">
          <input type="checkbox"> Potwierdzenie zapoznania się z <a
            href="#"
            style="color: #3772FF;"
          >obowiązkiem informacyjnym</a>
        </div>

        <div class="text-center m-w-100 mt-3">
          <button class="login_button">
            Zarejestruj się
          </button>
        </div>

      </div>
    </div>

    <div class="image col-sm-0 col-xl-5" />

  </div>

</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
body {
  background-color: white;
}
p {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 400;
}
.form {
  margin-left: 14%;
  margin-right: auto;
}
.header {
  margin-top: 118px;
}
.image {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://summitsalonacademyperrysburg.edu/wp-content/uploads/2020/06/Untitled-design.jpg");
}
.main_data {
  font-weight: 500;
  font-size: 20px;
  line-height: 44px;
  color: #000000;
  margin-top: 50px;
}
.email_input {
  height: 54px;
  border-radius: 13px;
  border: 3px solid #DFDFE6;
  padding: 16px;
  margin-bottom: 10px;
  background-color: #f6f6f633;
}
.email_input input {
  outline: none;
  font-size: 16px;
  color: #333;
  background-color: #f6f6f633;
  border: 3px;
  padding: 10px;
  width: 93%;
}
.email_input img {
  width: 7%;
}
.email_input input:focus {
  border: none;
}
.remember_me {
  background: #FCFCFD;
  /* Color/Dark/Dark 2 */

  border: 1px solid #87898E;
  border-radius: 3px;
}
.login_button {
  width: 40%;
  height: 56px;
  background: #87E1F9;
  border-radius: 50px;
  flex: none;
  flex-grow: 0;
  font-weight: 600;
  color: #ffffff;
  border: none;
  margin-top: 16px;
}
.forget_pass {
  color: #87E1F9;
}
.checkboxes {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;

  .divider-text {
    position: relative;
    display: inline-block;
    font-size: 0.9375rem;
    padding: 0 1rem;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    // divider icon font size

    i,
    svg {
      height: 1rem;
      width: 1rem;
      font-size: 1rem;
    }

    // divider border

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      border-top: 1px solid #dedede;
    }

    // divider spacing
    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }

}

</style>
